<template>
    <!--编辑页与详情页相同-->
    <div class="BrandDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" label-width="80px" size="small">
                <el-form-item label="编码">
                    <el-input readonly v-model="form.code" />
                </el-form-item>
                <el-form-item label="名字">
                    <el-input readonly v-model="form.name" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'BrandDetail',
    props: ['form'],
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
